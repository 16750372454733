<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.tujuanRujukan') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="tujuanRujukan.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.poly') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="tujuanRujukan.nama"
                  v-validate="'required'"
                  data-vv-as="Nama"
                  name="nama"
                  :class="{'is-invalid': errors.has('nama') }"
                />
                <div
                  v-show="errors.first('nama')"
                  class="invalid-feedback"
                >{{ errors.first('nama') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.grupRujukan') }}:</label>
              <div class="col-lg-6">
                <grup-rujukan-select
                  :changedValue.sync="tujuanRujukan.grupRujukan"
                  :id-select-picker="'grupRujukan' + tujuanRujukan.id"
                  :selected-picker="tujuanRujukan.grupRujukan"
                  :is-validate="true"
                ></grup-rujukan-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >Close</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import GrupRujukanSelect from "../../_select/GrupRujukan";
import TujuanRujukan from "../../../model/tujuan-rujukan-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const TujuanRujukanRepository = RepositoryFactory.get("tujuanRujukan");
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    GrupRujukanSelect
  },
  data() {
    return {
      tujuanRujukan: new TujuanRujukan()
    };
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed: {
    ...mapState('MasterStore', {
      modalName: state => state.modalName,
      dataEdit: state => state.dataEdit
    }),
  },
  methods: {
    ...mapActions({
      createNew: 'MasterStore/createNew',
      updateById: 'MasterStore/updateById'
    }),
    ...mapMutations({
      clearData: 'MasterStore/CLEAR_DATA'
    }),
    updateData: function () {
      if (this.dataEdit) {
        this.tujuanRujukan = Object.assign({}, this.dataEdit);
      }
    },
    submitData: function () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.tujuanRujukan.id) {
            this.updateById({ repository: TujuanRujukanRepository.updateTujuanRujukan, data: this.tujuanRujukan });
          } else {
            this.createNew({ repository: TujuanRujukanRepository.createTujuanRujukan, data: this.tujuanRujukan });
          }
          return;
        }
      });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    this.updateData();
  }
}
</script>
